<div class="main-container">
	<div class="background-container">
		<img src="../assets/amy-killian.jpeg"
			class="background-image"
			alt>
		<div class="text-overlay">
			<div class="wedding-text">Amy + Killian</div>
			<div class="wedding-date">Sept, 21st, 2024</div>
			<button (click)="fileInput.click()"
				class="upload-button">
				Upload Photos
			</button>
			<button (click)="goToUploadedPhotos()"
				class="upload-button">
				See all uploaded photos
			</button>
			<input #fileInput
				(change)="previewPhotos($event)"
				type="file"
				multiple
				style="display: none;">
		</div>

	</div>

	<div *ngIf="selectedPhotos.length > 0"
		class="preview-container">
		<h3>Selected Photos:</h3>
		<div *ngFor="let photo of selectedPhotos"
			class="photo-preview">
			<img [src]="photo.url"
				[alt]="photo.name">
		</div>
		<button (click)="confirmUpload()"
			class="final-upload-button">
			Final Upload
		</button>
	</div>

</div>
